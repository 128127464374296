
import { mapActions, mapGetters } from 'vuex'
import PDEOneTimeCode from '@/components/pde/PDEOneTimeCode.vue'

export default {
  name: 'TwoFactorAuth',

  components: {
    PDEOneTimeCode,
  },

  props: {
    factorId: {
      type: String,
      default: '',
    },

    loginOptions: {
      type: Object,
      default: () => ({}),
    },

    stateToken: {
      type: String,
      default: '',
    },
  },
  emits: ['showLogin'],
  data: () => ({
    otcError: false,
    otcDisabled: false,
    resendDisabled: true,
  }),

  computed: {
    ...mapGetters('identity', ['loginName']),

    localPartSegments() {
      const localPart = this.loginName.split('@')[0]
      return localPart.replace(/([._+-]+)/g, '$&,').split(',').filter(part => part)
    },

    domainName() {
      return this.loginName.split('@')[1]
    },

    resendCodeStatusStyle() {
      if (this.resendDisabled) {
        return 'text-black-500'
      }
      return 'underline text-highlight-800 hover:text-highlight-800 cursor-pointer'
    },
  },

  mounted() {
    /**
     * Once the Two Step verification is required, a one time code is sent to the email. This
     * setTimeout is used because we need to wait at least 5 seconds before ask for a new code.
     * Setting it to the value of otcDisabled ensures the resend button doesn't become clickable
     * while a code is currently submitting
    */
    setTimeout(() => {
      this.resendDisabled = this.otcDisabled
    }, 5000)
  },

  methods: {
    ...mapActions('identity', ['verify', 'resendMfaCode']),

    async handleSubmit(passCode) {
      this.otcDisabled = true
      this.resendDisabled = true
      this.otcError = false

      const data = {
        factorId: this.factorId,
        passCode,
        stateToken: this.stateToken,
      }

      try {
        const { redirectUrl } = await this.verify({ data, loginOptions: this.loginOptions })
        window.location.assign(redirectUrl)
      } catch (error) {
        this.otcDisabled = false
        this.resendDisabled = false
        this.otcError = true
      }
    },

    async handleResendCode() {
      this.resendDisabled = true
      this.otcError = false

      // Setting it to the value of otcDisabled ensures the resend button doesn't become clickable
      // while a code is currently submitting
      setTimeout(() => {
        this.resendDisabled = this.otcDisabled
      }, 30000)

      const data = {
        factorId: this.factorId,
        passCode: '',
        stateToken: this.stateToken,
      }
      this.$refs.otc.updateOneTimeCode()

      try {
        await this.resendMfaCode({ data })
      } catch (error) {
        // Until we introduce more comprehensive error handling we'll just send the user back to
        // the login form
        this.$emit('showLogin')
      }
    },
  },
}
