import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  ref: "form",
  name: "one-time-code",
  action: "#"
}
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "flex text-center justify-between" }
const _hoisted_4 = ["for"]
const _hoisted_5 = ["id", "onUpdate:modelValue", "tabindex"]
const _hoisted_6 = {
  key: 0,
  class: "text-error-900 text-12 mt-8"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createElementVNode("fieldset", {
      class: "max-w-280 m-auto border-0",
      disabled: $props.disabled
    }, [
      _cache[7] || (_cache[7] = _createElementVNode("legend", { class: "sr-only" }, " One Time Code ", -1)),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (n) => {
          return _createElementVNode("div", {
            key: `otc-${n}`
          }, [
            _createElementVNode("label", {
              for: `otc-${n - 1}`,
              class: "sr-only"
            }, "Number " + _toDisplayString(n), 9, _hoisted_4),
            _withDirectives(_createElementVNode("input", {
              id: `otc-${n - 1}`,
              ref_for: true,
              ref: `otc-${n - 1}`,
              "onUpdate:modelValue": $event => ((_ctx.oneTimeCode[n - 1]) = $event),
              autocomplete: "one-time-code",
              class: _normalizeClass($options.inputClasses),
              inputmode: "numeric",
              pattern: "[0-9]",
              required: "",
              tabindex: $options.setTabIndex(n-1),
              type: "number",
              onFocus: _cache[0] || (_cache[0] = (...args) => ($options.checkFocus && $options.checkFocus(...args))),
              onInput: _cache[1] || (_cache[1] = (...args) => ($options.checkInput && $options.checkInput(...args))),
              onKeydown: [
                _cache[2] || (_cache[2] = (...args) => ($options.checkKeydown && $options.checkKeydown(...args))),
                _cache[5] || (_cache[5] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["up","down"]))
              ],
              onKeyup: _cache[3] || (_cache[3] = (...args) => ($options.checkKeyup && $options.checkKeyup(...args))),
              onPaste: _cache[4] || (_cache[4] = (...args) => ($options.checkPaste && $options.checkPaste(...args))),
              onWheel: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"]))
            }, null, 42, _hoisted_5), [
              [_vModelText, _ctx.oneTimeCode[n - 1]]
            ])
          ])
        }), 64))
      ]),
      ($props.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString($props.errorMessage), 1))
        : _createCommentVNode("", true)
    ], 8, _hoisted_2)
  ], 512))
}